<template>
        <div class="loginPanel">
                <div class="lpTitle">Вход</div>
                <div class="lp1">
                        <div class="lp1_text">Первый раз в системе?</div>
                        <buttonNone text='Зарегистрироваться!' @clickButton="gotoRegitster"></buttonNone>
                </div>
                <input type="text" placeholder="Электронная почта" class="email mt40" v-model="email" @keyup.enter="enter" :class="{error : errfield.indexOf('email') != -1}"/>
                <input type="password" placeholder="Пароль" class="ps" v-model="ps" @keyup.enter="enter" :class="{error : errfield.indexOf('ps') != -1}"/>
                <div class="dvError mt20" :class="{viewError : errorText}">{{ errorText }}</div>
                <buttonRed class="binput mt20" text='Войти' @clickButton="login" :disable="email=='' || ps==''"></buttonRed>
                <buttonNone class="bps mt20" text='Восстановить пароль' @clickButton="getpass"></buttonNone>
        </div>
</template>

<script>
import ButtonRed from "../../common/ButtonRed"
import ButtonNone from "../../common/ButtonNone"
import g from "../../globals";

export default {
        components: {ButtonRed, ButtonNone},

        data: function () {
                return {
                        email: "",
                        ps: "",
                        errorText: "",
                        errfield: []
                }
        },
        watch : {
                email() {
                        this.errfield = []
                }
        },
        methods: {
                enter() {
                        this.email = this.email.trim();
                        this.ps = this.ps.trim();
                        if(!this.email || !this.ps) return
                        this.login()
                },

                async login() {
                        try {
                                this.email = this.email.trim();
                                this.ps = this.ps.trim();
                                let data = await g.postUrl("auth/login", {email: this.email, ps: this.ps});
                                g.setStorage("AUTH_TOKEN", "Bearer " + data.accessToken);
                                let tk = g.getStorage("AUTH_TOKEN");
                                location.replace("/");
                        } catch (e) {
                                if (e.property == "wait") this.$emit("error", e, this.email);
                                else {
                                        this.errfield = [];
                                        this.errfield.push("email", "ps");
                                        this.errorText = e.message;
                                }
                        }
                },
                gotoRegitster() {
                        this.$emit("register");
                },
                getpass() {
                        this.$emit("getpass");
                }

        }
}
</script>
