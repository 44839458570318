window.rnd = function (cnt) {
        return Math.floor(Math.random() * cnt);
}

window.isset = function (el) {
        if (el === 0 || el === "") return true;
        return ((el || null) != null)
}

window.clone = function (obj) {
        return JSON.parse(JSON.stringify(obj));
}

window.findIdx = (arr, val, fieldname, defNone) => {
        fieldname = fieldname || "value";
        defNone = defNone || null;
        let fndIdx = defNone;
        arr.some((p, idx) => {
                if (p[fieldname] == val) {
                        fndIdx = idx;
                        return true;
                }
                return false
        });
        return fndIdx;
}


let g = {
        target: "server",
        user: null,
        accessToken: null,
        mode: null,
        bblock: false,
        basePath : process.env.VUE_APP_SERVER + "/",

        getScale(ww, hh, WS, HS) {
                let kfW = ww / hh;      // коэффициент соотношения сторон экрана
                let kfI = WS / HS;     // коэффициент соотношения сторон в идеале
                if (kfW > kfI) {  // Есть лишняя ширина - подстраиваемся под высоту hh остается родной экранной
                        ww = hh * kfI; // Ширина получается новая
                } else { // Есть лишняя высота - подстраиваемся под ширину ww остается родной экранной
                        hh = ww / kfI; // Высота получается новая
                }

                return ww / WS;      // Масштаб
        },

        setCursor(node, cursor) {
                while (!node.container && node.parent) node = node.parent;
                if (node.container) node.container().style.cursor = cursor;
        },

        setFront(node) {
                node.zIndex(node.parent.getChildren().length - 1);
        },

        setUser(dataUser) {
                if (!dataUser) {
                        this.clearUser();
                        return;
                }
                this.user = {id: dataUser.id, name: dataUser.name};
                this.accessToken = dataUser.accessToken;
        },

        clearUser() {
                this.user = null;
                this.accessToken = null;
        },

        initAuthHeaders() {
                return this.getStorage("AUTH_TOKEN");
        },

        setStorage(key, value) {
                localStorage.setItem(key, JSON.stringify(value));
        },

        getStorage(key) {
                try {
                        return JSON.parse(localStorage.getItem(key));
                } catch (e) {
                        return null;
                }
        },

        removeStorage(key) {
                localStorage.removeItem(key);
        },

        postUrl(url, param) {
                switch (this.target) {
                        case "server":
                                return new Promise(async (resolve, reject) => {
                                        try {
                                                let options = {headers: {Authorization: this.initAuthHeaders()}};
                                                let data = await axios.post(this.basePath + url, param, options);
                                                resolve(data.data);
                                        } catch (e) {
                                                reject(e.response.data)
                                        }
                                });

                        case "desktop":
                                return ipcRenderer.invoke(url, param);
                }
        },

        async postUrlSimple(url, param) {
                try {
                        return await this.postUrl(url, param);
                } catch (e) {
                        alert(e);
                        return null;
                }
        },

        clone(obj) {
                return JSON.parse(JSON.stringify(obj));
        },

        block(bBlock) {
                this.bblock = bBlock;
        },

        isBlock() {
                return this.bblock;
        }
};

export default g;
