import g from "../globals";

export const button_mixin = {
	props : ["text", "disable"],

	data : function() {
		return {
			pushed : false
		}
	},

	methods : {
		down() {
			if(g.isBlock()) return;
			if(this.disable == true) return;
			this.pushed = true;
		},
		leave() {
			if(this.disable == true) return;
			this.pushed = false;
		},
		bClick( ) {
			if(g.isBlock()) return;
			if(this.disable == true) return;
			this.pushed = false;
			this.$emit("clickButton");
		}
	}
}
