<template>
        <div class="loginPanel">
                <div class="messageTitle messageRight">Условия использования</div>
                <div class="bigText mt50">
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                        Бла-бла-бла<br>
                </div>
                <buttonNone class="mt50" text='Вернуться в окно регистрации' @clickButton="gotoRegitster"></buttonNone>
        </div>
</template>

<script>

import ButtonNone from "../../common/ButtonNone";

export default {
        props: ["email"],
        components: {ButtonNone},
        data: function () {
                return {}
        },

        methods: {
                gotoRegitster() {
                        this.$emit("gotoRegitster");
                }
        }
}
</script>
