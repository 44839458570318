<template>
        <div class="buttonGray" :class="{pushed:pushed, disable : disable}" @mousedown="down" @mouseleave="leave" @click="bClick">{{text}}</div>
</template>

<script>
        import {button_mixin} from "./button_mixin";

	export default {
                mixins : [button_mixin],
	}
</script>
