import { createWebHistory, createRouter } from 'vue-router';
import Atlas from './components/Atlas.vue'
import Mesh from './components/Mesh.vue'
import AtlasProfile from './components/AtlasProfile.vue'

export const router = createRouter({
	history : createWebHistory(""),
        routes : [
		{path: "/login", name: 'atlas', component: Atlas},
		{path: "/forgot/:id", name: 'forgot', component: Atlas},
		{path: "/confirm/:id", name: 'confirm', component: Atlas},
		{path: "/mesh/:id", name: 'mesh', component: Mesh},
		{path: "/viewProfile", name: 'profile', component: AtlasProfile}
	]
})
