<template>
        <div class="loginPanel">
                <div class="messageTitle messageRight">Успешно</div>
                <div class="messageText mt50">На ваш адрес электронной почты:</div>
                <div class="messageText messageTextRed mt10">{{ email }}</div>
                <div class="messageText mt10">отправлена ссылка для восстановления пароля.</div>
                <div class="messageText mt10">Указанная в письме ссылка будет активна в течение 15 минут.</div>
                <div class="messageText mt10">Если вы не получили письмо, проверьте папку «Спам» или попробуйте снова.
                </div>
        </div>
</template>

<script>

export default {
        props: ["email"],
        data: function () {
                return {}
        },

        methods: {}
}
</script>
