<template>
        <div class="loginPanel">
                <div class="messageTitle messageRight">Политика конфиденциальности</div>
                <div class="descp mt50 polsa">
                        <div class="title" style="padding-top: 0">Пользовательское соглашение</div>
                        Настоящее Соглашение определяет условия использования Пользователями материалов и
                        сервисов сайта «Школьный атлас» (далее — «Сайт»).
                        <div class="title">1. Предмет Пользовательского соглашения:</div>
                        Пользовательское соглашение вступает в силу с момента выражения вами согласия с его
                        условиями путем регистрации на сайте. Соглашение может быть изменено администрацией сайта
                        без какого-либо специального уведомления. Новая редакция соглашения вступает в силу по
                        истечении 3 (трех) дней с момента ее публикации, если иное не предусмотрено новой редакцией
                        Соглашения. Действующая редакция Пользовательского соглашения всегда находится на странице
                        по адресу <a href="imgs/soglashenie.pdf" target="_blank">https://atlas.gramota.ru/imgs/soglashenie.pdf</a>.
                        <div class="title">2. Регистрация:</div>
                        <p>2.1. Вы соглашаетесь предоставить при заполнении регистрационной формы правдивую, точную и
                                полную информацию о себе и поддерживать эту информацию в актуальном состоянии. Если вы
                                предоставляете неверную информацию или есть серьезные основания полагать, что
                                предоставленная вами информация неверна, неполна или неточна, ваша регистрация может
                                быть
                                приостановлена либо отменена.</p>
                        <p>2.2. Вы несете ответственность за безопасность вашего логина и пароля, а также за любые
                                действия на платформе «Школьный атлас»,
                                произведенные от вашего имени (под вашей учетной записью).
                                Вы соглашаетесь с тем, что вы обязаны немедленно уведомить администрацию платформы о
                                любом случае неавторизованного (не разрешенного вами) доступа
                                с вашим логином и паролем и/или о любом нарушении безопасности.</p>
                        <p>2.3. Зарегистрировавшись на сайте, вы получаете непередаваемое право пользоваться своей
                                учетной записью (логином и паролем) для доступа к сервисам сайта. Вы не имеете права
                                передавать доступ к своей учетной записи (логин и пароль) третьему лицу, а также не
                                имеете
                                права получать его от третьего лица иначе, чем с письменного согласия администрации
                                сайта.
                                2.4. Вы согласны с тем, что администрация сайта оставляет за собой право прекратить
                                действие
                                вашего логина и пароля и/или удалить вашу учетную запись при неиспользовании доступа в
                                течение долгого периода времени или при нарушении Пользовательского соглашения.
                                Администрация сайта может также удалить вашу учетную запись по вашему запросу.</p>
                        <div class="title">3. Общие положения об использовании:</div>
                        <p>3.1. Информация, карты, текст, программы, музыка, звуки, изображения, видео и другие
                                материалы («контент»), размещенные на сайте, являются объектами авторских прав и
                                интеллектуальной собственности их владельцев. При любом использовании и размещении
                                контента вы обязаны соблюдать законы об авторском праве и интеллектуальной
                                собственности.</p>
                        <p>3.2. Контент может содержать ссылки на другие ресурсы. Вы признаете и соглашаетесь с тем, что
                                администрация сайта не несет никакой ответственности за доступность этих ресурсов и за
                                их
                                содержание, а также за любые последствия, связанные с их использованием.</p>
                        <p>3.3. Вы признаете, что администрация сайта может устанавливать ограничения в использовании
                                сервисов, в том числе: технические и содержательные требования к контенту, максимальный
                                размер загружаемого файла или дискового пространства, максимальное количество обращений
                                к
                                сервису за указанный период времени и т. д.</p>
                        <div class="title">4. Ограничения по использованию:</div>
                        <p>4.1. Вы соглашаетесь не использовать сайт для:</p>
                        <p>
                                а) размещения контента, который является незаконным, вредоносным, угрожающим,
                                клеветническим, оскорбляет нравственность, нарушает авторские права, пропагандирует
                                ненависть или дискриминацию людей по расовому, этническому, половому, религиозному,
                                социальному признакам, призывает к насилию, содержит оскорбления в адрес конкретных лиц
                                или организаций;</p>
                        <p>б) размещения контента, содержащего вирусы или другие вредоносные программы,
                                предназначенные для нарушения или ограничения функциональности какого-либо оборудования
                                или программ, для осуществления несанкционированного доступа, размещения серийных
                                номеров к коммерческим программным продуктам и программ для их генерации, логинов,
                                паролей и прочих средств для получения несанкционированного доступа к платным ресурсам в
                                интернете, а также ссылок на вышеуказанную информацию;</p>
                        <p>в) размещения не разрешенной специальным образом рекламной информации, спама, «писем
                                счастья» и любой другой навязчивой информации;</p>
                        <p>г) размещения ссылок на ресурсы, содержание которых противоречит действующему
                                законодательству Российской Федерации;</p>
                        <p>д) сбора и хранения персональных данных других лиц, размещения персональных данных
                                (адресов электронной почты, телефонов, паспортных данных и пр.) других пользователей или
                                третьих лиц без их предварительного согласия;</p>
                        <p>е) нарушения нормальной работы и сервисов сайта;</p>
                        <p>ж) содействия действиям, направленным на нарушение Пользовательского соглашения.</p>
                        <p>4.2. Вы признаете, что администрация сайта оставляет за собой право по своему собственному
                                усмотрению изменять (модерировать) или удалять любой размещенный вами контент,
                                приостанавливать или ограничивать ваш доступ ко всем или к любому из сервисов сайта в
                                случае
                                нарушения вами Пользовательского соглашения.</p>
                        <div class="title">5. Освобождение от гарантий:</div>
                        <p>Вы понимаете и соглашаетесь с тем, что:</p>
                        <p>а) Администрация сайта не гарантирует, что: сервисы будут соответствовать вашим требованиям;
                                сервисы будут предоставляться непрерывно, быстро, надежно и без ошибок; результаты,
                                которые
                                могут быть получены с использованием сервисов, будут точными и надежными; качество
                                какоголибо продукта, услуги, информации и пр., полученного с использованием сервисов,
                                будет
                                соответствовать вашим ожиданиям;</p>
                        <p>б) Администрация сайта не несет ответственность за любые действия пользователей,
                                размещенный ими контент, а также последствия, которые могут возникнуть при его
                                использовании.</p>
                        <div class="title">6. Общая информация:</div>
                        <p>Пользовательское соглашение регламентирует использование вами сервисов сайта. Принятие
                                Пользовательского соглашения не освобождает вас от необходимости соблюдать законы
                                Российской Федерации. Все возможные споры по поводу Пользовательского соглашения будут
                                разрешаться по нормам российского права.</p>
                        <p>С вопросами и предложениями, касающихся Пользовательского соглашения, обращайтесь по
                                адресу info@atlasgramota.ru</p>
                </div>
                <buttonNone class="mt50" text='Вернуться в окно регистрации' @clickButton="gotoRegitster"></buttonNone>
        </div>
</template>

<script>

import ButtonNone from "../../common/ButtonNone";

export default {
        props: ["email"],
        components: {ButtonNone},
        data: function () {
                return {}
        },

        methods: {
                gotoRegitster() {
                        this.$emit("gotoRegitster");
                }
        }
}
</script>
