<template>
	<div class="loginPanel">
                <div class="messageTitle messageRight">Успешно</div>
                <div class="messageText mt50">На указанный вами адрес электронной почты выслано сообщение со
                        ссылкой на подтверждение регистрации.
                </div>
                <div class="messageText mt10">Для завершения регистрации, пожалуйста, перейдите по ссылке в письме.</div>
                <buttonNone class="bclose mt50" text='Отправить повторное письмо' @clickButton="sendPost"></buttonNone>
        </div>
</template>

<script>
        import ButtonNone from "../../common/ButtonNone"
        import g from "../../globals";

	export default {
                props : ["email"],
                components : {ButtonNone},

		data : function() {
			return {
			}
		},

                methods : {
                        async sendPost() {
                                await g.postUrl("auth/postConfirm", { email: this.email});
                        }
                }
	}
</script>
