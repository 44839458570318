<template>
        <div></div>
</template>

<script>

import g from "@/globals";

export default {
        mounted() {
                try {
                        if(mesh && mesh !== "{{accessToken}}") {
                                g.setStorage("AUTH_TOKEN", "Bearer " + mesh);
                                location.href = "/";
                        }
                } catch(e) {}
        }
}

</script>
